<template>
  <MDBCard class="m-3">
    <MDBCardBody>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example Input<span class="text-danger">*</span></label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <div>
                <label for="exampleFormControlInput1" class="form-label text-dark">Example Check<span class="text-danger">*</span></label>  
              </div>
              <div>
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  Example Check 1
                </label>  
              </div>
              <div>
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  Example Check 2
                </label>  
              </div>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <div>
                <label for="exampleFormControlInput1" class="form-label text-dark">Example Radio<span class="text-danger">*</span></label>  
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                <label class="form-check-label" for="flexRadioDefault1">
                  Example Radio 1
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                <label class="form-check-label" for="flexRadioDefault2">
                  Example Radio 2
                </label>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <div>
                <label for="exampleFormControlInput1" class="form-label text-dark">Example Select<span class="text-danger">*</span></label>  
              </div>
              <select class="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example Textarea<span class="text-danger">*</span></label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example DatePicker<span class="text-danger">*</span></label>
              <MDBDatepicker format="YYYY-MM-DD"></MDBDatepicker>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example TimePicker<span class="text-danger">*</span></label>
              <MDBTimepicker ></MDBTimepicker>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example Switch<span class="text-danger">*</span></label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                <label class="form-check-label" for="flexSwitchCheckDefault">Example Switch 1</label>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="12">
            <div class="mb-3">
              <Ckeditor5></Ckeditor5>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example Select Tree<span class="text-danger">*</span></label>
              <div class="tree">
                <ul class="main">
                  <li>
                    <div class="title">
                      <i class="fas fa-caret-right tree-btn" @click="TreeToggle"></i>
                      <div class="item">
                        <input class="form-check-input" type="checkbox" value="" id="tree" @click="SelectAll">
                        <i class="fas fa-folder tree-btn me-2" @click="TreeToggle"></i>
                        <label class="form-check-label" for="tree">
                          Example Select Tree
                        </label>
                      </div>
                    </div>
                    <ul class="sub">
                      <li>
                        <div class="item ps-4">
                          <input class="form-check-input" type="checkbox" value="" id="tree1">
                          <i class="fas fa-file tree-btn me-2" @click="TreeToggle"></i>
                          <label class="form-check-label" for="tree1">
                            Example Select Tree 1
                          </label>  
                        </div>
                      </li>
                      <li>
                        <div class="item ps-4">
                          <input class="form-check-input" type="checkbox" value="" id="tree2">
                          <i class="fas fa-file tree-btn me-2" @click="TreeToggle"></i>
                          <label class="form-check-label" for="tree2">
                            Example Select Tree 2
                          </label>  
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
                </div>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label text-dark">Example File Input 1<span class="text-danger">*</span></label>
              <input type="file" class="form-control" >
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <FileUpload2></FileUpload2>
            </div>
          </MDBCol>
          <MDBCol md="4">
            <div class="mb-3">
              <Slim></Slim>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
</template>

<style scoped>
  .tree ul.main{
    /*margin: 0;*/
    padding: 0;
  }
  .tree li {
    list-style: none;
  }
  .tree .sub {
    display: none;
  }
  .tree ul.sub.active{
    display: block;
  }
  .tree .item {
    display: flex;
  }
  .tree .title {
    display: flex;
  }
  .tree .tree-btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jFiler-input-dragDrop {
    display: block;
    width: 343px;
    margin: 0 auto 25px auto;
    padding: 25px;
    color: #8d9499;
    color: #97A1A8;
    background: #fff;
    border: 2px dashed #C8CBCE;
    text-align: center;
    -webkit-transition: box-shadow 0.3s, border-color 0.3s;
    -moz-transition: box-shadow 0.3s, border-color 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s;
  }
  .jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa;
  }
  .upload2 {
    display: none;
  }
</style>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBDatepicker, MDBTimepicker } from 'mdb-vue-ui-kit';
import FileUpload2 from '@/components/Plugin/FileUpload2';
import Ckeditor5 from '@/components/Plugin/Ckeditor5';
import Slim from '@/components/Plugin/Slim';

export default {
  name: "Plugin",
  components: {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBDatepicker,
    MDBTimepicker,
    FileUpload2,
    Ckeditor5,
    Slim
  },
  setup() {
    const TreeToggle = function(event) {
      let sub = event.target.parentNode.nextSibling;
      let icon = event.target;
      if(icon.classList.contains("fa-caret-right")){
        sub.classList.add("active");
        icon.classList.add("fa-caret-down");
        icon.classList.remove("fa-caret-right");
      }else{
        sub.classList.remove("active");
        icon.classList.add("fa-caret-right");
        icon.classList.remove("fa-caret-down");
      }
    }

    const SelectAll = function(event) {
      console.log(event.target)
    }

    return {
      TreeToggle,
      SelectAll
    }
  }
};
</script>